import React from "react";

const CustomerSay = () => {

  return (
    <section className='container'>
      <div className="row">
        <div className="col-md-12">
          
        </div>
      </div>
    </section>
  );
};

export default CustomerSay;
